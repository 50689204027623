import 'jquery-ui';

import 'bootstrap';
import 'tom-select';

import RailsUjs from '@rails/ujs';
RailsUjs.start();

import './global/devise.js.erb';

import './partials/dropdown';
import "@fortawesome/fontawesome-free/js/all";

window.update_recaptcha_token = (el_id,token) => {
    const hiddenInput = document.getElementById(el_id);
    hiddenInput.value = token;
    hiddenInput.innerHTML = '';
};
